import { default as _91id_93KhdvgSfcshMeta } from "/app/pages/books/[id].vue?macro=true";
import { default as indextEIsPare8rMeta } from "/app/pages/books/index.vue?macro=true";
import { default as _91id_932MicVftUYkMeta } from "/app/pages/characters/[id].vue?macro=true";
import { default as _91id_93rtVFT0hPvPMeta } from "/app/pages/groupings/[id].vue?macro=true";
import { default as indexpjJo4kcX2YMeta } from "/app/pages/groupings/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93qjDUgPokdSMeta } from "/app/pages/printers/[id].vue?macro=true";
import { default as indexqHCPMm9Gq1Meta } from "/app/pages/printers/index.vue?macro=true";
export default [
  {
    name: "books-id",
    path: "/books/:id()",
    component: () => import("/app/pages/books/[id].vue")
  },
  {
    name: "books",
    path: "/books",
    component: () => import("/app/pages/books/index.vue")
  },
  {
    name: "characters-id",
    path: "/characters/:id()",
    component: () => import("/app/pages/characters/[id].vue")
  },
  {
    name: "groupings-id",
    path: "/groupings/:id()",
    component: () => import("/app/pages/groupings/[id].vue")
  },
  {
    name: "groupings",
    path: "/groupings",
    component: () => import("/app/pages/groupings/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "printers-id",
    path: "/printers/:id()",
    component: () => import("/app/pages/printers/[id].vue")
  },
  {
    name: "printers",
    path: "/printers",
    component: () => import("/app/pages/printers/index.vue")
  }
]